.widget {
	position: absolute;
	top: 20px;
	background-color: white;
}

.widget.active {
	border: 1px solid;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	display: flex;
	padding: 10px;
	width: 95%;
}

.flexItem {
	flex-grow: 1;
}

button {
	font-size: 1.1em;
	margin-right: 5px;
	border: 1px solid #400099;
	background-color: white;
	border-radius: 3px;
	box-shadow: none;
	padding: 5px;
}

.debugPrinterButton {
	font-size: 1.25em;
	padding: 10px;
	border-radius: 0;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	border-left: none;
}

h3 {
	margin-top: 0;
	margin-bottom: 10px;
	font-weight: normal;
}

.exitButton {
	position: absolute;
	right: 0;
	top: 0;
	margin-top: -1px;
	margin-right: -1px;
	padding: 10px;
}
